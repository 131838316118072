@use '../../styleguide/colors.scss';
@use '../../styleguide/vars.scss';
@use '../../styleguide/mixins.scss';

.summary {
  background: white;
  padding: vars.$gutter;
  margin-bottom: vars.$nodeSpacing;
  @include mixins.dropShadow;
  @include mixins.rounded;

  &Header {
    font-size: 20px;
    font-weight: 700;
    line-height: 1.5rem;
    margin-bottom: vars.$gutter;
    font-stretch: condensed;
  }

  &Info {
    display: grid;
    grid-template-columns: auto max-content;
    grid-template-rows: 2rem;
    margin-bottom: vars.$gutter;
  }

  @media screen and (min-width: vars.$extra-large) {
    position: sticky;
    top: calc(80px + vars.$gutter);
  }
}

.agreement {
  color: colors.$secondary-text;
  font-size: 0.8rem;
  padding: 0 10px;
  margin-bottom: vars.$gutter;
  display: flex;

  a {
    font-size: 0.8rem;
  }
}

.paymentMethods {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: center;
  margin-top: vars.$gutter;
  column-gap: vars.$gutter;
}

.loginNotice {
  margin: vars.$nodeSpacing 0;
}

.price {
  margin-left: auto;
}

.totalPrice,
.totalPriceLabel {
  font-weight: bold;
  font-stretch: condensed;
  display: flex;
  border-top: 1px solid colors.$grey;
  margin-top: vars.$gutter;
  padding-top: vars.$gutter;
}

.totalPrice {
  position: relative;
  line-height: 100%;
  align-items: center;
  justify-content: flex-end;
}

.cantProceedError {
  color: colors.$grey-default;
  text-align: center;
  line-height: 20px;
  padding: 8px 8px 0 8px;
}

.totalQuantity {
  margin-left: auto;
}

.guestCheckoutButton {
  margin-top: vars.$nodeSpacing;
}
