@use '../../styleguide/colors.scss';
@use '../../styleguide/vars.scss';

.allShops {
  margin-bottom: 30px;
  flex-grow: 1;
}

.navigation {
  border-bottom: 1px solid colors.$grey;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  overflow: scroll;

  @media screen and (min-width: vars.$large) {
    overflow: initial;
  }
}

.letter {
  border-bottom: 2px solid transparent;
  cursor: pointer;
  padding: 12px 5px;
  min-width: 60px;
  text-align: center;

  @media screen and (min-width: vars.$large) {
    min-width: initial;
  }
}

.selected {
  border-bottom: 2px solid colors.$primary-color;
  color: colors.$primary-color;
}

.disabled {
  color: colors.$grey;
  pointer-events: none;
}

.section {
  margin-top: 30px;
}

.shopList {
  row-gap: 10px;
  display: flex;
  flex-direction: column;
}

.wrapWebsite {
  position: relative;
  column-gap: 30px;

  @media screen and (min-width: vars.$medium) {
    column-count: 2;
  }
  @media screen and (min-width: vars.$large) {
    column-count: 3;
  }
  @media screen and (min-width: vars.$extra-large) {
    column-count: 6;
  }
}

.title {
  color: colors.$secondary-text;
  font-weight: 300;
  margin-bottom: 15px;
  @media screen and (min-width: vars.$medium) {
    margin-top: 30px;
  }
}

.title span {
  font-weight: bold;
  font-stretch: condensed;
}

.sectionTitle {
  border-bottom: 1px solid colors.$grey;
  font-size: 1rem;
  margin-bottom: 15px;
}

.shopName {
  color: colors.$secondary-text;
  text-decoration: none;
}

.website {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 30px;
}

.wrapperSearchInputShop {
  overflow: hidden;
  width: 100%;
  position: relative;
  label span {
    color: colors.$secondary-text;
    overflow: hidden;
    pointer-events: none;
    position: absolute;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 90%;
    left: 30px;
    top: 30px;
  }
}

.searchInputShop {
  outline: none;
  border: none;
  width: 100%;
  border-bottom: 1px solid var(--secondary-text);
  background-color: inherit;
  margin: 30px 0;
  position: relative;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xOS44MzM2IDE4LjIxNThMMTQuNjU4OCAxMy4wMDI3QzE0LjQ2NDUgMTIuODA3MyAxNC40NDUxIDEyLjUwNjIgMTQuNTk1NCAxMi4yNzUzQzE2LjcxNzEgOS4wMjEwNSAxNi4yMjIyIDQuNTQ1NjcgMTMuMDkyIDEuODcyNTZDMTAuMDggLTAuNjk4ODQ0IDUuNDk1NDUgLTAuNjEwODQ1IDIuNTc3MTkgMi4wNjc5OEMtMC43NzI1MDYgNS4xNDI4MSAtMC44NTcwNzcgMTAuMzc2NSAyLjMyNDYyIDEzLjU2MDRDNS4wMzc3NCAxNi4yNzU4IDkuMjM3MTQgMTYuNjA1NSAxMi4zMjExIDE0LjU2NTZDMTIuNTUyNSAxNC40MTI0IDEyLjg1NiAxNC40MzI0IDEzLjA1MTQgMTQuNjI5TDE4LjIxNDggMTkuODMxMkMxOC40MzgyIDIwLjA1NTggMTguODAwNSAyMC4wNTY0IDE5LjAyNDUgMTkuODMyNEwxOS44MzI1IDE5LjAyMzhDMjAuMDU1NCAxOC44MDA5IDIwLjA1NTkgMTguNDM5OCAxOS44MzM2IDE4LjIxNThaTTMuOTQwNjEgMTEuOTQyN0MxLjczMjYyIDkuNzMzMDQgMS43MzI2MiA2LjEzNzY1IDMuOTQwNjEgMy45Mjc5NkM2LjE0ODU5IDEuNzE4MjcgOS43NDExNCAxLjcxNzcgMTEuOTQ5MSAzLjkyNzk2QzE0LjE1NzEgNi4xMzc2NSAxNC4xNTcxIDkuNzMzMDQgMTEuOTQ5MSAxMS45NDI3QzkuNzQxMTQgMTQuMTUyNCA2LjE0ODU5IDE0LjE1MjQgMy45NDA2MSAxMS45NDI3WiIgZmlsbD0iIzMyOTNFNiIvPgo8L3N2Zz4K);
  background-size: 17px 17px;
  background-position: left center;
  background-repeat: no-repeat;
  padding-left: 30px;
}
