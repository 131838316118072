@use '../../styleguide/colors.scss';
@use '../../styleguide/vars.scss';

.pageHeader {
  font-weight: bold;
  grid-column: header;
  margin-bottom: 32px;
}

.root {
  display: grid;
  grid-column-gap: 30px;
  grid-template:
    'header' auto
    'content' auto
    'sidebar' auto;
  margin-bottom: 100px;

  @media screen and (min-width: vars.$extra-large) {
    grid-template:
      'header header' auto
      'content sidebar' auto / 9fr 3fr;

    &Empty {
      grid-template:
        'header' auto
        'content' auto;

      .shopsList {
        display: flex;
        margin: auto 0;
      }
    }
  }
}

.shopsList {
  grid-column: content;

  @media screen and (min-width: vars.$extra-large) {
    min-height: 50vh;
  }
}

.pendingContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.sidebar {
  grid-column: sidebar;
}

.shop {
  background: white;
  padding: 30px;
  margin-bottom: 15px;

  &Name {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1rem;
    word-break: break-all;
  }
}

.product {
  display: grid;

  grid-template-areas:
    'coppic copname copname copname'
    '. copquantity copprice copactions';
  grid-template-columns: max-content auto min-content auto;
  grid-column-gap: vars.$gutter;
  align-items: center;
  margin-top: vars.$gutter;
  padding: vars.$nodeSpacing 0;

  @media screen and (min-width: vars.$medium) {
    grid-template: 'coppic copname copquantity copprice copactions' auto / min-content auto max-content min-content min-content;
  }

  &Unavailable {
    .productQuantity {
      grid-column: copquantity / copprice;
    }

    .productName,
    .productPicture,
    .productQuantity {
      opacity: 0.5;
    }
  }

  & + & {
    border-top: 1px solid colors.$grey;
  }
  &Link {
    text-decoration: none;
  }
  &Picture {
    width: 64px;
    height: 64px;
    display: flex;
    justify-content: center;
    grid-area: coppic;
    margin: auto;

    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }

    @media screen and (min-width: vars.$large) {
      &Picture {
        width: 130px;
        height: 130px;
      }
    }
  }

  &Name {
    grid-area: copname;
    margin: vars.$nodeSpacing 0;
    word-break: break-word;
    max-width: fit-content;
    display: flex;
    flex-direction: column;
  }

  &Quantity {
    grid-area: copquantity;
  }

  &Price {
    font-size: 1.4rem;
    font-weight: bold;
    grid-area: copprice;
    width: auto;
    margin-left: auto;
    text-align: right;

    @media screen and (min-width: vars.$medium) {
      margin: initial;
      width: 6rem;
    }
  }

  &Actions {
    display: flex;
    grid-area: copactions;
    column-gap: vars.$nodeSpacing;
    color: grey;
    margin-left: auto;
  }

  &Size {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.2px;
    font-stretch: condensed;
    color: #555;
  }
}

.pug {
  display: block;
  width: 114px;
  height: 144px;
  margin-bottom: 1.5rem;
  background-image: url(../../images/icons/Pug-hands-up.svg);
}

.pugPlace {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.pugShadow {
  z-index: -1;
  color: colors.$grey;
  position: absolute;
}

.empty {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding: 0 1rem;
  color: colors.$secondary-text;
  row-gap: 1.5rem;
  max-width: 450px;
  margin: 0 auto;

  @media screen and (min-width: vars.$large) {
    flex-direction: row;
    max-width: initial;
    column-gap: vars.$gutter;

    .pugPlace {
      width: 300px;
    }

    .emptyMessage {
      max-width: 450px;
    }
  }
}

.deleteIcon {
  &:hover {
    color: colors.$error;
    cursor: pointer;
  }
}

.paymentPending {
  display: flex;
  padding: 32px 24px;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
  border-radius: 4px;
  background: #fff;
  margin-bottom: 16px;
  flex-direction: column-reverse;

  @media screen and (min-width: vars.$medium) {
    padding: 32px 40px;
    gap: 0;
    flex-direction: row;
  }

  @media screen and (min-width: vars.$extra-large) {
    padding: 32px 56px;
    gap: 80px;
  }

  &Left {
    display: flex;
    max-width: 100%;
    flex-direction: column;
    gap: 32px;

    @media screen and (min-width: vars.$medium) {
      flex-direction: row;
    }

    & a {
      text-decoration: none;
      width: fit-content;
      & button {
        padding: 0 16px;
        & svg {
          margin-left: 8px;
        }
      }
    }

    & span {
      color: #555;
      font-weight: 400;
      font-stretch: condensed;
      font-size: 18px;
      letter-spacing: 0.2px;
    }

    & h1 {
      font-stretch: condensed;
      font-size: 32px;
      font-weight: 400;
      letter-spacing: 0.2px;
      line-height: 38px;
      margin-bottom: 16px;

      @media screen and (min-width: vars.$medium) {
        font-size: 36px;
        letter-spacing: 0.4px;
        line-height: 42px;
      }

      @media screen and (min-width: vars.$extra-large) {
        font-size: 48px;
        line-height: 56px;
      }
    }

    @media screen and (min-width: vars.$medium) {
      max-width: calc(100% - 240px);
      gap: 32px;
      align-items: flex-start;
      flex-direction: column;
    }
    @media screen and (min-width: vars.$extra-large) {
      max-width: 420px;
    }
  }

  &Right {
    background-image: url('../../images/icons/pug-suit.png');
    width: 185px;
    height: 210px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    @media screen and (min-width: vars.$medium) {
      width: 276px;
      height: 316px;
    }
  }
}

.paymentSupport {
  display: flex;
  padding: 16px 15px;
  align-items: center;
  gap: 16px;
  border-radius: 4px;
  background: #fff;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 16px;

  @media screen and (min-width: vars.$medium) {
    flex-direction: row;
  }

  & div {
    width: 100%;
    @media screen and (min-width: vars.$medium) {
      width: auto;
    }
  }

  & button {
    color: colors.$secondary-color;
    background: white;
    border: 1px solid colors.$secondary-color;
    padding: 0px 16px;
  }

  & p {
    font-size: 16px;
    font-stretch: condensed;
    font-weight: 700;
    line-height: 24px;
  }

  & span {
    color: #555;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 400;
    font-stretch: initial;
    font-size: 14px;
    line-height: 20px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
}
