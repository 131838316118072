@use '../../styleguide/colors.scss';

.likedProductIcon {
  color: colors.$red-default;
}

.wishlistBtn {
  background: none;
  border: none;
  color: black;
  cursor: pointer;
  font-size: 1.3rem;
  outline: 0;

  &:hover {
    color: colors.$primary-color;
  }
}
